<template>
  <v-skeleton-loader
    v-if="loading"
    type="article, actions"
  ></v-skeleton-loader>

  <v-card
    v-else
    :loading="loading"
  >
    <v-card-title class="d-flex justify-space-between">
      <h4>Ajouter un utilisateur</h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiAccountPlus }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label for="firstname">Prénom</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="firstname"
              v-model="newUser.firstname"
              :rules="[v => !!v || 'Le prénom est requis']"
              outlined
              dense
              placeholder="Prénom"
              @change="updateNames"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="lastname">Nom</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="lastname"
              v-model="newUser.lastname"
              :rules="[v => !!v || 'Le nom est requis']"
              outlined
              dense
              placeholder="Nom"
              @change="updateNames"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="username">Nom d'utilisateur</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="username"
              v-model="newUser.username"
              :rules="usernameRules"
              outlined
              dense
              placeholder="Nom d'utilisateur"
              @change="errUsername === false"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="displayName">Nom d'affichage</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="displayName"
              v-model="newUser.displayName"
              :rules="[v => !!v || 'Le nom d\'affichage est requis']"
              placeholder="Nom d'affichage"
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="email">E-mail</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="email"
              v-model="newUser.email"
              :rules="emailRules"
              md="9"
              outlined
              dense
              placeholder="E-mail"
              @change="errEmail === false"
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="status">Rôle</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-select
              id="status"
              v-model="newUser.status"
              :rules="[v => !!v || 'Le rôle est requis']"
              outlined
              dense
              :items="statusList"
              item-text="text"
              item-value="statusName"
              placeholder="Rôle"
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-end mt-5">
          <v-btn
            class="mx-2"
            outlined
            type="reset"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            @click="createUser"
          >
            Créer l'utilisateur
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

import { mdiAccountPlus } from '@mdi/js'

export default {
  data() {
    return {
      valid: true,
      loading: true,
      usernameRules: [
        !this.errUsername || 'Ce nom d\'utilisateur est déjà utilisé',
        v => !v || /^[a-zA-Z0-9]*$/.test(v) || 'Caractères spéciaux non autorisés'],
      emailRules: [
        !this.errEmail || 'Cet e-mail est déjà utilisé',
        v => !v || /.+@.+\..+/.test(v) || 'L\'adresse e-mail doit être valide',
      ],
      errUsername: false,
      errEmail: false,
      newUser: {
        firstname: '',
        lastname: '',
        username: '',
        status: null,
        email: '',
        displayName: '',
        role: 1,
        provider: 'local',
        confirmed: false,
      },
      displayNamePH: '',
      usernamePH: '',
      icons: {
        mdiAccountPlus,
      },
      statusList: [
        {
          text: 'Administrateur',
          statusName: 'admin',
        },
        {
          text: 'Utilisateur',
          statusName: 'user',
        },
        {
          text: 'Tuteur',
          statusName: 'tuteur',
        },
      ],
    }
  },
  mounted() {
    this.loading = false
  },
  methods: {
    createUser() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/create-user`,
          this.newUser)
          .then(() => {
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Nouvel utilisateur créé',
              value: true,
            })
          })
          .catch(err => {
            const error = err.response.data
            this.errUsername = (error.statusCode === 400) && (error.data.key === 'duplicateUsername')
            this.errEmail = (error.statusCode === 400) && (error.data.key === 'duplicateEmail')
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la création de l\'utilisateur',
              value: true,
            })
          })
          .finally(
            this.loading = false,
          )
      }
    },
    updateNames() {
      this.newUser.username = this.newUser.firstname.toLowerCase().replace(/[^a-zA-Z]/ig, '')
      this.newUser.displayName = `${this.newUser.firstname.charAt(0).toUpperCase()}${this.newUser.firstname.slice(1).toLowerCase()} ${this.newUser.lastname.charAt(0).toUpperCase()}.`
    },
  },
}
</script>

<style>

</style>
